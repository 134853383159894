const SALogo = props => (
	<svg viewBox="0 0 250 100">
		<g>
			<g>
				<path fill="#282757" d="M98.7,72.2H88.1l-6.4-10.6c-0.5-0.8-0.9-1.5-1.4-2.1c-0.4-0.6-0.8-1.1-1.3-1.6c-0.4-0.4-0.9-0.8-1.4-1 c-0.5-0.2-1-0.4-1.6-0.4h-2.5v15.7h-9.2V31.3h14.6c9.9,0,14.9,3.7,14.9,11.1c0,1.3-0.2,2.7-0.7,3.9c-0.4,1.2-1.1,2.3-1.9,3.3 c-0.8,1-1.8,1.8-2.9,2.5c-1.2,0.7-2.4,1.3-3.8,1.7V54c0.6,0.2,1.2,0.5,1.8,0.9c0.6,0.5,1.1,1,1.7,1.5c0.5,0.6,1,1.2,1.5,1.8 c0.5,0.7,0.9,1.3,1.3,1.9L98.7,72.2z M73.5,38.2v11.4h4c1.8,0.1,3.5-0.5,4.8-1.7c1.2-1.1,1.9-2.7,1.8-4.3c0-3.6-2.1-5.4-6.4-5.4 L73.5,38.2z"/>
				<path fill="#282757" d="M115.3,73c-4.9,0-8.7-1.4-11.5-4.1c-2.8-2.7-4.2-6.4-4.2-11.1c0-4.8,1.4-8.6,4.3-11.3 c2.9-2.7,6.8-4.1,11.7-4.1c4.9,0,8.7,1.4,11.4,4.1c2.8,2.7,4.1,6.3,4.1,10.8c0,4.8-1.4,8.7-4.3,11.5C124.1,71.5,120.3,73,115.3,73 z M115.6,49.2c-1.9-0.1-3.7,0.7-5,2.2c-1.2,1.5-1.8,3.5-1.8,6.2c0,5.6,2.3,8.4,6.8,8.4c4.3,0,6.5-2.9,6.5-8.6 C122.1,51.9,119.9,49.2,115.6,49.2L115.6,49.2z"/>
				<path fill="#282757" d="M145.6,68.8L145.6,68.8l-0.1,3.4h-9V29h9v18.4h0.1c2-3.3,5.6-5.2,9.5-5.1c3.8,0,6.7,1.3,8.7,3.9 c2,2.6,3.1,6.1,3.1,10.6c0,4.9-1.2,8.8-3.6,11.7c-2.3,2.9-5.8,4.5-9.5,4.4C150.2,73,147.5,71.6,145.6,68.8z M145.4,56.5v3 c-0.1,1.7,0.5,3.4,1.6,4.7c1,1.2,2.6,1.9,4.2,1.8c1.9,0.1,3.7-0.8,4.8-2.4c1.1-1.6,1.7-3.8,1.7-6.8c0-2.4-0.5-4.3-1.6-5.7 c-1.1-1.4-2.7-2.1-4.4-2c-1.7,0-3.4,0.7-4.5,2C145.9,52.7,145.3,54.6,145.4,56.5z"/>
				<path fill="#282757" d="M199.5,60.2h-19.1c0.3,4.2,3,6.4,8,6.4c3,0.1,5.9-0.7,8.5-2.3v6.5c-2.7,1.4-6.2,2.2-10.6,2.2 c-4.7,0-8.4-1.3-11-3.9c-2.6-2.6-3.9-6.3-3.9-10.9c0-4.8,1.4-8.7,4.2-11.5c2.7-2.8,6.5-4.3,10.4-4.3c4.3,0,7.6,1.3,9.9,3.8 c2.3,2.5,3.5,5.9,3.5,10.3L199.5,60.2z M191.1,54.7c0-4.2-1.7-6.3-5.1-6.3c-1.5,0-2.8,0.7-3.8,1.8c-1.1,1.3-1.8,2.8-1.9,4.5H191.1 z"/>
				<path fill="#282757" d="M223.4,51.2c-1.2-0.6-2.5-0.9-3.8-0.9c-1.8-0.1-3.5,0.7-4.6,2.2c-1.2,1.7-1.8,3.8-1.7,5.9v14h-9V43h9v5.4 h0.1c1.4-4,4-5.9,7.7-5.9c0.8,0,1.5,0.1,2.3,0.3L223.4,51.2z"/>
				<path fill="#282757" d="M247.6,71.9c-1.3,0.7-3.3,1.1-6,1.1c-6.4,0-9.5-3.3-9.5-9.9V49.7h-4.7V43h4.7v-6.3l9-2.6V43h6.6v6.6h-6.6 v11.8c0,3,1.2,4.6,3.6,4.6c1,0,2-0.3,2.9-0.8L247.6,71.9z"/>
			</g>
			<g>
				<path fill="#80C0E9" d="M26.7,63.5h8.1c0.1,0,0.1,0,0.1,0.1v22.7c0,0.1,0,0.1-0.1,0.1h-8.1c-0.1,0-0.1,0-0.1-0.1V63.6 C26.6,63.6,26.7,63.5,26.7,63.5z"/>
				<path fill="#BF2A5F" d="M13.1,63.5h8.1c0.1,0,0.1,0,0.1,0.1v22.7c0,0.1,0,0.1-0.1,0.1h-8.1c-0.1,0-0.1,0-0.1-0.1V63.6 C13,63.6,13.1,63.5,13.1,63.5z"/>
				<line fill="none" stroke="#282757" strokeWidth="0.9271" strokeMiterlimit="10" x1="24" y1="23.2" x2="24" y2="12.5"/>
				<path fill="#282757" d="M26.4,12.7c0-1.3-1.1-2.4-2.4-2.4s-2.4,1.1-2.4,2.4s1.1,2.4,2.4,2.4S26.4,14,26.4,12.7L26.4,12.7z"/>
				<path fill="#6DB780" d="M22.9,20.7H25c11.4,0,20.6,9.2,20.6,20.6V63c0,3.5-2.8,6.3-6.3,6.3c0,0,0,0,0,0H8.6 c-3.5,0-6.3-2.8-6.3-6.3V41.2C2.4,29.9,11.6,20.7,22.9,20.7L22.9,20.7z"/>
				<path fill="#282757" d="M13.1,85.1h8.3c0.8,0,1.4,0.6,1.4,1.4v1.7c0,0.8-0.6,1.4-1.4,1.4h-8.3c-0.8,0-1.4-0.6-1.4-1.4v-1.7 C11.6,85.8,12.3,85.1,13.1,85.1z"/>
				<path fill="#FFFFFF" d="M15.8,36h16.4c4.2,0,7.5,3.4,7.5,7.5V47c0,4.2-3.4,7.5-7.5,7.5H15.8c-4.2,0-7.5-3.4-7.5-7.5v-3.5 C8.2,39.4,11.6,36,15.8,36z"/>
				<ellipse fill="#282757" cx="18.1" cy="45.3" rx="1.9" ry="3"/>
				<ellipse fill="#282757" cx="29.8" cy="45.3" rx="1.9" ry="3"/>
				<path fill="#282757" d="M26.6,85.1h8.3c0.8,0,1.4,0.6,1.4,1.4v1.7c0,0.8-0.6,1.4-1.4,1.4h-8.3c-0.8,0-1.4-0.6-1.4-1.4v-1.7 C25.2,85.8,25.9,85.1,26.6,85.1z"/>
			</g>
		</g>
		</svg>

)

export default SALogo;